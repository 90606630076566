<template>
  <div class="pilot-rec-form">
    <h1>{{ title }}</h1>

    <FormKit
      type="form"
      v-model="formData"
      :form-class="submitted ? 'hide' : 'show'"
      submit-label="Submit"
      @submit="submitHandler"
    >
      <FormKit label="Candidate name"
        name="candidateName"
        type="text"
        placeholder=""
        help=""
        validation="required"
      /> 
      <!-- <FormKit label="Candidate email"
        type="text"
        name="email"
        placeholder=""
        help=""
        validation="required|email"
      /> -->


      <FormKit label="Recommended position"
        name="position"
        type="select"
        :options="[
          'Lead PIC',
          'PIC',
          'SIC',
        ]"
      />

      <FormKit label="Tail Number"
        name="tailNumber"
        type="text"
        placeholder="N102JE"
        help=""
      />
      <FormKit label="Salary requirement"
        name="costSalary"
        type="text"
        placeholder="90000"
        help=""
        validation="required|number"
      />
      <FormKit label="Last recruitment"
        name="lastRecruitment"
        type="date"
        placeholder=""
        value="2022-01-01"
      />

      <!-- Toggles whether certain dates are visible in section below -->
      <FormKit label="Training requirement"
        name="trainingReq"
        v-model="trainingRequirement"
        type="select"
        :options="{
          'initial': 'Initial',
          'recurrent': 'Recurrent',
        }"
        validation="required"
      />
      <FormKit label="Training cost"
        name="costTraining"
        type="text"
        placeholder="90000"
        help=""
        validation="number"
      />
      <FormKit label="Total time"
        name="timeTotal"
        type="text"
        placeholder="8000"
        help=""
        validation="required|number"
      />
      <FormKit label="Time in (aircraft) type"
        name="timeInType"
        type="text"
        placeholder="1200"
        help=""
        validation="required|number"
      />

      <!-- CURRENT STATUS -->
      <FormKit label="Current status"
        name="currentStatus"
        type="select"
        :options="[
          'Contracting - Is Available ASAP',
          'Currently employed - must give notice',
          'Current JE employee',
          'Unemployed',
          'N/A',
        ]"
      />
      <!-- CURRENTLY RESIDES -->
      <FormKit label="Currently resides"
        name="currentlyResides"
        type="text"
        placeholder="City, State"
        help=""
        validation=""
      />
      <!-- WILLING TO RELOCATE -->
      <FormKit label="Willing to relocate?"
        name="willingToRelocate"
        type="select"
        :options="[
          'N/A',
          'Yes',
          'No',
        ]"
      />
      <!-- RELOCATION COST -->
      <FormKit label="Relocation cost"
        name="relocationCost"
        type="text"
        placeholder="2000"
        help=""
        validation="number"
      />

      <!-- TRAINING DATES SECTION -->
      <section class="training-dates">
        <h2>Dates</h2>

        <FormKit label="Hire Date"
          name="dateHire"
          type="date"
          placeholder=""
          value="2022-01-01"
        />
          
        <!-- Relocation Date -->
        <div class="date-range">
          <h4>Relocation</h4>
          <FormKit label="Start date"
            name="dateRelocation_start"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
          <FormKit label="End date"
            name="dateRelocation_end"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
        </div>

        <!-- Indoc Date -->
        <div class="date-range">
          <h4>Indoc</h4>
          <FormKit label="Start date"
            name="dateIndoc_start"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
          <FormKit label="End date"
            name="dateIndoc_end"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
        </div>
        
        <!-- Initial / Recurrent Training -->
        <div 
          v-if="trainingRequirement === 'initial'" 
          class="date-range"
        >
          <h4>Initial Aircraft Training</h4>
          <FormKit
            label="Start date"
            name="dateInitialAircraftTraining_start"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
          <FormKit
            label="End date"
            name="dateInitialAircraftTraining_end"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
        </div>
        <div 
          v-else-if="trainingRequirement === 'recurrent'"
          class="date-range"
        >
          <h4>Recurrent Aircraft Training</h4>
          <FormKit
            label="Start date"
            name="dateRecurrentAircraftTraining_start"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
          <FormKit
            label="End date"
            name="dateRecurrentAircraftTraining_end"
            type="date"
            placeholder=""
            value="2022-01-01"
          />
        </div>
        

        <FormKit label="P91 Ready Date"
          name="dateP91ready"
          type="date"
          placeholder=""
          value="2022-01-01"
        />

        <FormKit label="P135 Ready Date"
          name="dateP135Ready"
          type="date"
          placeholder=""
          value="2022-01-01"
        />
      </section>

      <!-- CANDIDATE NOTES -->
      <FormKit label="Candidate notes"
        name="candidateNotes"
        type="textarea"
        maxlength="1000" 
        rows="10"
        placeholder=""
      /> 
    
    </FormKit>

    <div v-if="submitted">
      <div class="pilot-rec-form__message">
        <strong>Submission successful!</strong>
      </div>
      <div>
        <a href="/">Submit another candidate?</a>
      </div>
    </div>

    <!-- DEBUG -->
    <!-- <pre wrap style="text-align:left">{{ formData }}</pre>  -->

    <div>
      <!-- <a href="mailto:recruitment@flyjetedge.com" target="_blank" rel="noodivener">Questions? Email us</a>. -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue'
import axios from 'axios'

// export default
export default {
  name: 'PilotRecruitment',
  
  components: {
  },

  props: {
    title: String
  },
  
  data() {
    const trainingRequirement = ref('initial')

    return {
      submitted: ref(false),
      formData: ref({}),
      trainingRequirement
    }
  },
  
  methods: {
    /**
     * 
     */
    async submitHandler() {
      console.log(' > FIRED. <')
      const payload = { ...this.formData }
      // Lets pretend this is an ajax request:
      // await new Promise((r) => submitForm(formData))

      Object.keys(payload).map((key) => {1
        const currentValue = payload[key]
        payload[key] = (currentValue !== undefined ? currentValue : '')
      })
      this.postForm(payload)
    },

    /**
     * 
     */
    async postForm (formData) {
      console.log('------------------------------')
      console.log(formData)
      
      const azureUrl = 'https://prod-182.westus.logic.azure.com:443/workflows/8d6b8699c22e4479b6f010d7af883456/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6vbSryZpJ_AxQGAMZpbCXJbei2NpAOMfw5PHsyInBHE'

      return axios.post(azureUrl, formData)
        .then(response => {
          console.log(response)
          this.submitted = true
        });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

$border_radius: 8px;

h1 {
  margin: 0 auto 2rem;
  font-size: 1.65rem;

  @media only screen and (max-width: 480px) {
    margin: 0 auto .75rem;
    font-size: 1.15rem;
  }
}

h2 {
  font-size: 1.25rem;
}

.pilot-rec-form {
  padding: 2rem;
  background: #fafafa;
  margin: 0 auto 2rem auto;
  border-radius: $border_radius;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px + 1px) {
    width: 65%;
  }
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    margin: 0 .5rem 2rem .5rem;
    width: auto;
    max-width: 100%;
    padding: 1rem;
  }

  &__message {
    color: #fff;
    padding: 1rem; 
    background: forestgreen;
    border-radius: 6px;
    max-width: 20rem;
    margin: 3rem auto;
    height: auto;
  }
  .formkit-form {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    transition: opacity 1.2s ease-in, height .85s ease-in;

    // Disable form after submit
    &.hide {
      pointer-events: none;
      overflow: hidden;
      opacity: 0;
      height: 0px;
    }

    .date-range {
      border-bottom: 1px solid rgba(100, 100, 100, .2);
      margin-bottom: 1.25rem;
      padding-bottom: 0.5rem;
      @media only screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    .formkit-outer {
      width: auto;
      display: flex;
      margin: auto;
      margin-bottom: .75rem;
      flex-direction: column;
      justify-content:center;
      
      @media only screen and (max-width: 480px) {
        margin-bottom: .25rem;
      }

      .formkit-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        padding: 0.25rem;
        width: 100%;
        
        .formkit-label,
        .formkit-inner {
          margin: 0;
          display: flex;
          font-size: .8rem;
        }

        // Left column (Label)
        .formkit-label {
          text-align: left;
          margin: auto 1rem auto 0;
          padding: 0;
          font-weight: normal;
          flex-basis: 45%;
        }
        //  Right column (Input)
        .formkit-inner {
          flex-basis: 55%;
        }

        .formkit-input:not(button) {
          background: #fff;
        }
        .formkit-input[type='submit'] {
          background-color: slategray;
          margin: 1rem auto;
          transition: opacity 0.3s ease-in;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }

  .training-dates {
    background: rgba(68, 125, 156, .4); // #447d9c;
    border-radius: $border_radius;
    border: 1px solid #bababa;
    padding: .25rem .35rem 1.25rem .35rem;
    margin: 1.25rem 0;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
