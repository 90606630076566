<template>
  <img alt="Vue logo" class="app-logo" src="./assets/jetedge-logo-vector.svg">
  <PilotRecruitment title="Pilot Recruitment"/>
</template>

<script>
import PilotRecruitment from './components/PilotRecruitment.vue'

export default {
  name: 'App',
  components: {
    PilotRecruitment
  },
  mounted() {
    document.title = "Pilot Recruitment" 
    document.description = "Jet Edge pilot recruitment"
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #bababa;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: .5rem;
}

.app-logo {
  max-height: 2rem;
  padding: 1rem 0 .75rem;
  width: auto;

  @media only screen and (max-width: 480px) {
    max-height: 1.25rem;
    padding: .75rem 0 .5rem;
  }
}
</style>
